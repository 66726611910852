/* @flow */

import type { FormData as FormDataType } from "@awardit/formaggio/src/types";

import React, { useState, useEffect } from "react";
import cn from "classnames";
import Button from "components/Button";
import { useClient } from "entrypoint/shared";
import { photoServiceSubmit } from "queries";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "state/messages";
import { useTranslate } from "@awardit/react-use-translate";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { PhotoServiceData } from "data";
import Field from "components/Field";

import styles from "./styles.scss";

type props = {
  open: boolean,
  setOpen: boolean => void,
  onSuccess: void => void,
};

const validation = rules([
  isRequired("storeName"),
]);

const UploadImagesOverlay = ({ open, setOpen, onSuccess }: props): React$Node => {
  const t = useTranslate();
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [state, setState] = useState<FormDataType>({});
  const errors = validation(state);
  const client = useClient();
  const sendMessage = useSendMessage();
  const photoServiceData = useData(PhotoServiceData);

  const clickHandler = async () => {
    const formData = new FormData();

    if (file) {
      formData.append("image", file);
      setLoading(true);
      try {
        const response = await fetch("/UploadImage", {
          method: "POST",
          credentials: "same-origin",
          body: formData,
        });
        const data = await response.json();

        if (data) {
          setUrl(data);
          postPhotoServiceData();
        }
        else {
          sendMessage(addMessage("SEND_IMAGE_ERROR", "error"));
        }
      }
      catch (e) {
        console.log(e);
        sendMessage(addMessage("SEND_IMAGE_ERROR", "error"));
      }
    }
  };

  const postPhotoServiceData = async () => {
    if (url &&
        state &&
        state.storeName !== null &&
        state.storeName !== undefined &&
        photoServiceData &&
        photoServiceData.state === "LOADED" &&
        photoServiceData.data.length > 0) {
      const data = {
        offerid: photoServiceData.data[0].offerid,
        title: state.storeName.toString(),
        descr: "",
        image: `${url}`,
      };

      const response = await client(photoServiceSubmit, { form: data });

      if (response.photoServiceSubmit.result.includes("ERROR")) {
        sendMessage(addMessage("SEND_IMAGE_ERROR", "error"));
        setOpen(false);
        setLoading(false);
      }
      else {
        sendMessage(addMessage("SEND_IMAGE_SUCCESS", "success"));
        setLoading(false);
        onSuccess();
      }
    }
  };

  useEffect(() => {
    if (url) {
      postPhotoServiceData();
    }
  }, [url]);

  const submit = e => {
    e.preventDefault();
    clickHandler();
  };

  return (
    <div
      className={cn({
        [styles.uploadContainer]: open,
        [styles.hidden]: !open,
      })}
    >
      <div className={styles.boxBackground} onClick={() => setOpen(false)} />
      <div className={styles.uploadContainer__Inner}>
        <p className={styles.title}>Ladda upp foto</p>
        <Form
          value={state}
          errors={errors}
          className={styles.form}
          onChange={x => setState({ ...state, ...x })}
          onSubmit={submit}
        >
          <Field name="storeName" type="text" label="Butiksnamn" />
          <div
            className={cn(styles.fileInputContainer, {
              [styles.disabledInput]: file,
            })}
          >
            {t("IMAGE_UPLOADER.CHOOSE_FILE")}
            {" "}
            <input
              type="file"
              name="image"
              accept="image/jpeg, image/jpg, image/png"
              onChange={e => (setFile(e.target.files[0]))}
            />
            <div className={styles.bgColor} />
          </div>
          <Button
            type="submit"
            variant="primary"
            className={styles.btn}
            disabled={!file}
            loading={loading}
          >
            {t("IMAGE_UPLOADER.UPLOAD_IMAGE_BTN")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default UploadImagesOverlay;

