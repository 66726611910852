/* @flow */

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Button from "components/Button";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  lastVisited: ?string,
};

const Checkout = ({ lastVisited }: Props): React$Node => {
  const t = useTranslate();
  const { push, location } = useHistory();
  const { routes: { redeemView, checkoutSuccessView } } = useContext(StoreInfoContext);
  const continueShopping = location.pathname === checkoutSuccessView?.url || location.pathname === "/checkout/cart";

  const gotoRedeemView = () => {
    if (redeemView?.url) {
      push(redeemView.url);
    }

    return null;
  };

  return (
    <Button
      className={cn("awardit-subMenuCheckoutButton", styles.close)}
      onClick={() => lastVisited ? push(lastVisited) : gotoRedeemView}
    >
      {t(continueShopping ? "CART.CONTINUE_SHOPPING" : "CART.CLOSE_CART")}
    </Button>
  );
};

export default Checkout;
