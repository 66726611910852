/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";
import cn from "classnames";

const HomeHero = (): React$Node => {
  const {
    content: {
      homeview: content,
    },
  } = useContext(StoreInfoContext);

  return (
    <div
      className={cn("awardit-homeHero", styles.hero)}
    >
      <picture className={styles.image}>
        <source
          srcSet={content.heroImageSmall ?? ""}
          media={`(max-width: ${parseInt(styles.tiny, 10) - 1}px)`}
        />
        <source
          srcSet={content.heroImageLarge ?? ""}
          media={`(min-width: ${parseInt(styles.tiny, 10)}px)`}
        />
        <img
          alt=""
          src={content.heroImageLarge ?? ""}
        />
      </picture>
      {(content.heroHeading || content.heroDescription || content.heroIcon) && (
        <div className={cn("awardit-homeHeroContent", styles.content)}>
          {content.heroIcon && (
            <div className={styles.heroIcon}>
              <img
                alt=""
                src={content.heroIcon}
              />
            </div>
          )}
          {content.heroHeading &&
            <h1>{content.heroHeading}</h1>
          }
          {/* eslint-disable react/no-danger */}
          {content.heroDescription &&
            <p
              dangerouslySetInnerHTML={{ __html: content.heroDescription }}
            />
          }
          {/* eslint-enable react/no-danger */}
        </div>
      )}
    </div>
  );
};

export default HomeHero;
