/* @flow */

import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useData } from "crustate/react";
import { CustomerData } from "data";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { SubNavHeader, SubNavList } from "components/AppHeader/Small/SubNav";
import SearchMenu from "components/AppHeader/SearchMenu";
import { mapBrands } from "helpers/appheader";
import ChevronIcon from "icons/chevron-small.svg";

import styles from "./styles.scss";

type Props = {
  closeHamburger: () => void,
  setHamburgerOpen: boolean => void,
  setSubNavOpen: boolean => void,
  closeMenu: () => void,
};

const Redeem = ({
  closeHamburger,
  setHamburgerOpen,
  setSubNavOpen,
  closeMenu,
}: Props): React$Node => {
  const t = useTranslate();
  const [thirdNav, setThirdNav] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const { categories, brands, routes } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;

  const categoriesFiltered = categories.filter(item => !memberTargetList ||
    memberTargetList.includes(item.awarditTargetId) ||
    !item.awarditTargetId);

  const closeThird = () => {
    setIsClosing(true);

    setTimeout(() => {
      setThirdNav(null);
      setIsClosing(false);
    }, parseInt(styles.animationDuration, 10));
  };

  return (
    <nav className={cn(styles.block)}>
      <SubNavHeader
        className="awardit-appHeaderSmallSubNavHeader"
        onBack={() => setSubNavOpen(false)}
        onClose={closeHamburger}
      >
        {(routes.redeemView && routes.redeemView.title) ?? ""}
      </SubNavHeader>
      <div className={cn("awardit-appHeaderSmallSubNavItems", styles.items)}>
        {routes.allProductsView && routes.allProductsView.toggle !== undefined &&
        routes.allProductsView.toggle &&
        routes.allProductsView.title && routes.allProductsView.url && (
          <NavLink
            to={routes.allProductsView.url}
            className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          >
            {routes.allProductsView?.title}
          </NavLink>
        )}

        <span
          className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          onClick={() => setThirdNav("categories")}
        >
          {t("HEADER.CATEGORIES")}
          <ChevronIcon />
        </span>

        <span
          className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          onClick={() => setThirdNav("brands")}
        >
          {t("HEADER.BRANDS")}
          <ChevronIcon />
        </span>

        <span
          className={cn("awardit-appHeaderSmallSubNavItem", styles.item)}
          onClick={() => setThirdNav("search")}
        >
          <span>{t("HEADER.SEARCH")}</span>
          <ChevronIcon />
        </span>
      </div>

      <nav className={cn(
        "awardit-appHeaderSmallSubNavCategoriesContainer",
        styles.categories,
        { [styles.active]: thirdNav === "categories" },
        { [styles.isClosing]: isClosing }
      )}
      >
        <SubNavHeader
          className="awardit-appHeaderSmallSubNavCategoriesHeader"
          onClose={closeHamburger}
          onBack={() => closeThird()}
        >
          {t("HEADER.CATEGORIES")}
        </SubNavHeader>
        <SubNavList
          className="awardit-appHeaderSmallSubNavCategoriesList"
          resourceType="categories"
          setHamburgerOpen={setHamburgerOpen}
          items={categoriesFiltered}
          onItemClick={() => {
            setThirdNav(null);
            closeMenu();
          }}
        />
      </nav>

      <nav className={cn(
        "awardit-appHeaderSmallSubNavBrandsContainer",
        styles.brands,
        { [styles.active]: thirdNav === "brands" },
        { [styles.isClosing]: isClosing }
      )}
      >
        <SubNavHeader
          className="awardit-appHeaderSmallSubNavBrandsHeader"
          onClose={closeHamburger}
          onBack={() => closeThird()}
        >
          {t("HEADER.BRANDS")}
        </SubNavHeader>
        {routes.brandsView && routes.brandsView.url &&
          <SubNavList
            className="awardit-appHeaderSmallSubNavBrandsList"
            setHamburgerOpen={setHamburgerOpen}
            items={mapBrands(routes.brandsView.url, brands)}
            resourceType="brands"
            onItemClick={() => {
              setThirdNav(null);
              closeMenu();
            }}
          />
        }
      </nav>

      <nav className={cn(
        "awardit-appHeaderSmallSubNavSearchContainer",
        styles.search,
        { [styles.active]: thirdNav === "search" },
        { [styles.isClosing]: isClosing })}
      >
        <SubNavHeader className="awardit-appHeaderSmallSubNavSearchHeader" onBack={() => closeThird()} onClose={closeHamburger}>
          {t("HEADER.SEARCH")}
        </SubNavHeader>
        <SearchMenu
          className="awardit-appHeaderSmallSubNavSearch"
          heading={t("HEADER.SEARCH")}
          onItemClick={closeMenu}
        />
      </nav>
    </nav>
  );
};

export default Redeem;
