/* @flow */

import type { StateData } from "crustate/react";

import { createStateData } from "crustate/react";

import { RouteModel } from "@crossroads/shop-state/route";
import { CustomerModel } from "state/customer";
import { QuoteModel } from "state/quote";
import { HomeModel } from "state/home";
import { RedeemModel } from "state/redeem";
import { OrderModel } from "state/order";
import { CheckoutStepModel } from "state/checkout-step";
import { BrandModel } from "@crossroads/shop-state/brand";
import { SearchModel } from "@crossroads/shop-state/search";
import { MessagesModel } from "state/messages";
import { NewsPostsModel } from "state/news-posts";
import { AffiliateModel } from "state/affiliate";
import { AffiliateCategoriesModel } from "state/affiliate-categories";
import { AffiliateListModel } from "state/affiliate-list";
import { AffiliateListSelectedModel } from "state/affiliate-list-selected";
import { TransactionsModel } from "state/transactions";
import { LeaderboardModel, LeaderboardDistrictsModel } from "state/leaderboard";
import { ReportFormModel, ReportListModel, ReportHistoryModel } from "state/reports";
import { VerifyListModel } from "state/verify-list";
import { ViewModeModel } from "state/view-mode";
import { ConnectedAccountsModel } from "state/connected-accounts";
import { QuestionnaireListModel } from "state/questionnaire-list";
import { QuizListModel } from "state/quiz-list";
import { QuizModel } from "state/quiz";
import { QuizHistoryModel, QuizHistoryListModel, QuizHistoryRowsModel } from "state/quiz-history";
import { TeampotMembersModel, TeampotHistoryModel, TeampotSettingsModel, TeampotStatusModel, TeampotActivitiesModel, TeampotHistoryItemsModel } from "state/teampot";
import { QuestionnaireModel } from "state/questionnaire";
import { DistrictInfoModel } from "state/district-info";
import { DistrictListModel } from "state/district-list";
import { AllProductsModel } from "@crossroads/shop-state/all-products";
import { OffersModel } from "state/offers";
import { TinkTransactionsModel } from "state/tink-transactions";
import { TinkProvidersModel } from "state/tink-providers";
import { CurrentPageInfoModel } from "state/current-page-info";
import { CurrentInfoItemModel } from "state/current-info-item";
import { CurrentInfoListModel } from "state/current-info-list";
import { PhotoServiceModel } from "state/photo-service";
import { PhotoServiceListModel } from "state/photo-service-list";
import { AllFormsModel, FormRowsModel, FormModel, FormSubmitsModel } from "state/forms";
import { CmsModel } from "state/cms";
import { RegisterFormConfigModel, AccountFormConfigModel } from "state/account-forms";
import { MemberGroupIsOwnerModel } from "state/member-group";

export const RouteData: StateData<typeof RouteModel> =
  createStateData<typeof RouteModel>(RouteModel);
export const QuoteData: StateData<typeof QuoteModel> =
  createStateData<typeof QuoteModel>(QuoteModel);
export const CustomerData: StateData<typeof CustomerModel> =
  createStateData<typeof CustomerModel>(CustomerModel);
export const HomeData: StateData<typeof HomeModel> =
  createStateData<typeof HomeModel>(HomeModel);
export const RedeemData: StateData<typeof RedeemModel> =
  createStateData<typeof RedeemModel>(RedeemModel);
export const BrandData: StateData<typeof BrandModel> =
  createStateData<typeof BrandModel>(BrandModel);
export const SearchData: StateData<typeof SearchModel> =
  createStateData<typeof SearchModel>(SearchModel);
export const OrderData: StateData<typeof OrderModel> =
  createStateData<typeof OrderModel>(OrderModel);
export const CheckoutStepData: StateData<typeof CheckoutStepModel> =
  createStateData<typeof CheckoutStepModel>(CheckoutStepModel);
export const MessagesData: StateData<typeof MessagesModel> =
  createStateData<typeof MessagesModel>(MessagesModel);
export const NewsPostsData: StateData<typeof NewsPostsModel> =
  createStateData<typeof NewsPostsModel>(NewsPostsModel);
export const TransactionsData: StateData<typeof TransactionsModel> =
  createStateData<typeof TransactionsModel>(TransactionsModel);
export const LeaderboardData: StateData<typeof LeaderboardModel> =
  createStateData<typeof LeaderboardModel>(LeaderboardModel);
export const LeaderboardDistrictsData: StateData<typeof LeaderboardDistrictsModel> =
  createStateData<typeof LeaderboardDistrictsModel>(LeaderboardDistrictsModel);

export const ReportFormData: StateData<typeof ReportFormModel> =
  createStateData<typeof ReportFormModel>(ReportFormModel);
export const ReportListData: StateData<typeof ReportListModel> =
  createStateData<typeof ReportListModel>(ReportListModel);
export const ReportHistoryData: StateData<typeof ReportHistoryModel> =
  createStateData<typeof ReportHistoryModel>(ReportHistoryModel);
export const VerifyListData: StateData<typeof VerifyListModel> =
  createStateData<typeof VerifyListModel>(VerifyListModel);
export const ViewModeData: StateData<typeof ViewModeModel> =
  createStateData<typeof ViewModeModel>(ViewModeModel);
export const DistrictInfoData: StateData<typeof DistrictInfoModel> =
  createStateData<typeof DistrictInfoModel>(DistrictInfoModel);
export const DistrictListData: StateData<typeof DistrictListModel> =
  createStateData<typeof DistrictListModel>(DistrictListModel);
export const AllProductsData: StateData<typeof AllProductsModel> =
  createStateData<typeof AllProductsModel>(AllProductsModel);
export const OffersData: StateData<typeof OffersModel> =
  createStateData<typeof OffersModel>(OffersModel);
export const CmsData: StateData<typeof CmsModel> =
  createStateData<typeof CmsModel>(CmsModel);

export const RegisterFormConfigData: StateData<typeof RegisterFormConfigModel> =
  createStateData<typeof RegisterFormConfigModel>(RegisterFormConfigModel);
export const AccountFormConfigData: StateData<typeof AccountFormConfigModel> =
createStateData<typeof AccountFormConfigModel>(AccountFormConfigModel);

export const TinkProvidersData: StateData<typeof TinkProvidersModel> =
  createStateData<typeof TinkProvidersModel>(TinkProvidersModel);
export const TinkTransactionsData: StateData<typeof TinkTransactionsModel> =
createStateData<typeof TinkTransactionsModel>(TinkTransactionsModel);

export const AffiliateListData: StateData<typeof AffiliateListModel> =
createStateData<typeof AffiliateListModel>(AffiliateListModel);
export const AffiliateListSelectedData: StateData<typeof AffiliateListSelectedModel> =
createStateData<typeof AffiliateListSelectedModel>(AffiliateListSelectedModel);
export const AffiliateCategoriesData: StateData<typeof AffiliateCategoriesModel> =
createStateData<typeof AffiliateCategoriesModel>(AffiliateCategoriesModel);
export const AffiliateData: StateData<typeof AffiliateModel> =
createStateData<typeof AffiliateModel>(AffiliateModel);
export const ConnectedAccountsData: StateData<typeof ConnectedAccountsModel> =
createStateData<typeof ConnectedAccountsModel>(ConnectedAccountsModel);
export const QuestionnaireListData: StateData<typeof QuestionnaireListModel> =
createStateData<typeof QuestionnaireListModel>(QuestionnaireListModel);
export const QuizListData: StateData<typeof QuizListModel> =
createStateData<typeof QuizListModel>(QuizListModel);
export const QuizData: StateData<typeof QuizModel> =
createStateData<typeof QuizModel>(QuizModel);
export const QuizHistoryData: StateData<typeof QuizHistoryModel> =
createStateData<typeof QuizHistoryModel>(QuizHistoryModel);
export const QuizHistoryRowsData: StateData<typeof QuizHistoryRowsModel> =
createStateData<typeof QuizHistoryRowsModel>(QuizHistoryRowsModel);
export const QuizHistoryListData: StateData<typeof QuizHistoryListModel> =
createStateData<typeof QuizHistoryListModel>(QuizHistoryListModel);
export const AllFormsData: StateData<typeof AllFormsModel> =
createStateData<typeof AllFormsModel>(AllFormsModel);
export const FormData: StateData<typeof FormModel> =
createStateData<typeof FormModel>(FormModel);
export const FormRowsData: StateData<typeof FormRowsModel> =
createStateData<typeof FormRowsModel>(FormRowsModel);
export const FormSubmitsData: StateData<typeof FormSubmitsModel> =
createStateData<typeof FormSubmitsModel>(FormSubmitsModel);
export const TeampotMembersData: StateData<typeof TeampotMembersModel> =
createStateData<typeof TeampotMembersModel>(TeampotMembersModel);
export const TeampotHistoryData: StateData<typeof TeampotHistoryModel> =
createStateData<typeof TeampotHistoryModel>(TeampotHistoryModel);
export const TeampotSettingsData: StateData<typeof TeampotSettingsModel> =
createStateData<typeof TeampotSettingsModel>(TeampotSettingsModel);
export const TeampotStatusData: StateData<typeof TeampotStatusModel> =
createStateData<typeof TeampotStatusModel>(TeampotStatusModel);
export const TeampotActivitiesData: StateData<typeof TeampotActivitiesModel> =
createStateData<typeof TeampotActivitiesModel>(TeampotActivitiesModel);
export const TeampotHistoryItemsData: StateData<typeof TeampotHistoryItemsModel> =
createStateData<typeof TeampotHistoryItemsModel>(TeampotHistoryItemsModel);
export const QuestionnaireData: StateData<typeof QuestionnaireModel> =
createStateData<typeof QuestionnaireModel>(QuestionnaireModel);
export const CurrentPageInfoData: StateData<typeof CurrentPageInfoModel> =
createStateData<typeof CurrentPageInfoModel>(CurrentPageInfoModel);
export const CurrentInfoListData: StateData<typeof CurrentInfoListModel> =
createStateData<typeof CurrentInfoListModel>(CurrentInfoListModel);
export const PhotoServiceData: StateData<typeof PhotoServiceModel> =
createStateData<typeof PhotoServiceModel>(PhotoServiceModel);
export const PhotoServiceListData: StateData<typeof PhotoServiceListModel> =
createStateData<typeof PhotoServiceListModel>(PhotoServiceListModel);
export const CurrentInfoItemData: StateData<typeof CurrentInfoItemModel> =
createStateData<typeof CurrentInfoItemModel>(CurrentInfoItemModel);
export const MemberGroupIsOwnerData: StateData<typeof MemberGroupIsOwnerModel> =
createStateData<typeof MemberGroupIsOwnerModel>(MemberGroupIsOwnerModel);
