/* @flow */

import type { Mode } from "state/view-mode";
import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { Menu, CartMini } from "@crossroads/ui-components";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import cn from "classnames";
import useFormat from "helpers/use-format";
import SystemMessages from "components/SystemMessages";
import Logo from "components/AppHeader/Logo";
import CustomerBadge from "components/CustomerBadge";
import { MODE } from "state/view-mode";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import SubMenu, { useHideSubMenu } from "components/AppHeader/Large/SubMenu";
import CartIcon from "icons/cart.svg";
import HamburgerIcon from "icons/hamburger.svg";
import AdminButton from "components/AccountView/AdminButton";

import styles from "./styles.scss";

type Props = {
  className: string,
  mode: Mode,
  setMode: Mode => void,
  onHomeView: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  lastVisited: ?string,
  customer: ?Customer,
  logoWidth: string,
  isScrolled: boolean,
  cartCount?: number,
  adminUser?: boolean,
};

const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long", day: "numeric" });
};

const noDimModes = [MODE.NORMAL, MODE.SEARCH, MODE.RECRUIT, MODE.TINK];

const AppHeaderLarge = ({
  mode,
  setMode,
  onHomeView,
  onCheckout,
  lastVisited,
  customer,
  logoWidth,
  isScrolled,
  onSuccess,
  cartCount,
  adminUser,
  className = "",
}: Props): React$Node => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const { routes: { accountView, checkoutView, homeView, teamPot, suppliersView } } = storeInfo;
  const { content: { accountview, tinkview } } = storeInfo;
  const { content: { appheader } } = storeInfo;
  const { formatPoints } = useFormat();
  let UPDATEDROUTES = null;

  if (tinkview.hideInNavbar !== undefined &&
    tinkview.hideInNavbar !== null &&
    tinkview.hideInNavbar === true) {
    UPDATEDROUTES = ROUTES.filter(item => item !== "accountTinkView");
  }

  const compactMenu = appheader.compactMenu !== undefined &&
    appheader.compactMenu !== null &&
    appheader.compactMenu === true;
  const nav = navRoutes(UPDATEDROUTES ? UPDATEDROUTES : ROUTES, storeInfo);
  const hideSubMenu = useHideSubMenu();
  const showAdminButton = Boolean(accountview.showAdministration) &&
    hideSubMenu &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;
  const expiringPoints = customer && customer.awardit.expiringPoints > 0 ?
    formatPoints(customer.awardit.expiringPoints) : null;
  const expiringPointsDate = customer && customer.awardit.expiringPointsDate ?
    formatDate(customer.awardit.expiringPointsDate) : null;

  return (
    <div className={styles.container}>
      <div className={cn(
        styles.block,
        className,
        { [styles.fullMenu]: !onHomeView }
      )}
      >
        <nav className={styles.nav}>
          {homeView && homeView.url &&
            <div className={styles.logoWrapper}>
              <Link className={cn("awardit-appHeaderLargeLogo", styles.item, styles.logo)} to={homeView.url ?? "/"}>
                <Logo
                  width={logoWidth}
                  height="47"
                  isInverted={isScrolled}
                  onClick={() => setMode(MODE.NORMAL)}
                />
              </Link>
            </div>
          }

          <div className={cn("awardit-appHeaderLargeLinks", styles.links)}>
            {!onCheckout && !compactMenu && nav.map(x => (x.url &&
              <NavLink
                key={x.url}
                to={x.url}
                activeClassName={styles.active}
                className={styles.item}
              >
                {x.title}
              </NavLink>
            ))}
            {teamPot &&
            teamPot.toggle !== undefined &&
            teamPot.toggle &&
            typeof teamPot.url === "string" &&
            teamPot.url.length > 0 &&
            !onCheckout &&
            adminUser === true &&
              <NavLink
                to={teamPot.url}
                activeClassName={styles.active}
                className={styles.item}
              >
                {teamPot.title}
              </NavLink>
            }
          </div>

          {compactMenu &&
            <Menu className={styles.item} icon={<HamburgerIcon />}>
              {!onCheckout && nav.map(x => (x.url &&
                <NavLink key={x.url} to={x.url} className={styles.menuItem}>
                  {x.title}
                </NavLink>
              ))}
              {!onCheckout && suppliersView && suppliersView.toggle !== undefined &&
              suppliersView.toggle && suppliersView.url && (
                <NavLink to={suppliersView.url} className={styles.menuItem}>
                  {suppliersView.title}
                </NavLink>
              )}
            </Menu>
          }

          <div className={cn("awardit-appHeaderLargeRight", styles.right)}>
            {expiringPointsDate && expiringPoints &&
            accountView && accountView.url && (
              <NavLink
                to={accountView.url}
                className={cn(styles.item, styles.expiresNotice)}
              >
                <div className={styles.exclamation}>!</div>
                <div className={styles.notice}>
                  <span className={styles.date}>
                    {`${t("ACCOUNT.EXPIRES")} ${expiringPointsDate}`}
                  </span>
                  <span className={styles.points}>
                    {expiringPoints}
                  </span>
                </div>
              </NavLink>
            )}

            {showAdminButton &&
              <div className={cn(styles.adminBtnContainer, "awardit-adminButton")}>
                {accountview.administration &&
                  <AdminButton text={accountview.administration} />
                }
              </div>
            }

            {accountView && accountView.toggle !== undefined &&
            accountView.toggle && accountView.url && (
              <NavLink
                to={accountView.url}
                activeClassName={styles.active}
                className={cn(styles.item, styles.item__customerBadge)}
              >
                <CustomerBadge customer={customer} />
              </NavLink>
            )}

            {typeof cartCount === "number" && cartCount > 0 && (
              <div
                className={cn("awardit-appHeaderLargeRightCart", styles.item)} onClick={() => {
                  if (cartCount === 0) {
                    return;
                  }

                  setMode(mode === MODE.CART ? MODE.NORMAL : MODE.CART);
                }}
              >
                <CartIcon className={styles.icon} />
                <span className={styles.cartCount}>{cartCount}</span>
              </div>
            )}
          </div>
        </nav>
      </div>

      <SubMenu
        mode={mode}
        setMode={setMode}
        lastVisited={lastVisited}
        onCheckout={onCheckout}
        onSuccess={onSuccess}
      />

      {!noDimModes.includes(mode) &&
        <div
          className={cn(styles.dim, { [styles.cart]: mode === MODE.CART })}
          onClick={() => setMode(MODE.NORMAL)}
        />
      }

      {mode === MODE.CART && checkoutView && checkoutView.url && (
        <div className={styles.miniCartWrapper}>
          <CartMini
            quoteData={quoteData}
            variant="b2b"
            altUrl={`${checkoutView.url}/cart`}
            ctaUrl={`${checkoutView.url}/shipping`}
            className={cn("awardit-miniCart", styles.desktop, styles.miniCart)}
            onClose={() => setMode(MODE.NORMAL)}
          />
        </div>
      )}

      <div>
        <SystemMessages />
      </div>
    </div>
  );
};

export default AppHeaderLarge;

