/* @flow */

import type { NewsPost } from "shop-state/types";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";

const NewsListItem = ({
  item: {
    title,
    publishedAt,
    content,
    id,
  },
}: { item: NewsPost }): React$Node => {
  const t = useTranslate();
  const { routes } = useContext(StoreInfoContext);
  const gaContext = useContext(AnalyticsContext);
  const gaRegister = () => {
    gaContext.registerContentClick(`News: ${title}`, id.toString());
  };

  const url = routes.newsListView && routes.newsListView.url && title ?
    `${routes.newsListView.url}/${encodeURIComponent(title)}` : "";

  return (
    <Link
      className={styles.block}
      to={url}
      onClick={gaRegister}
    >
      <div className={styles.shadow} />

      <header>
        <span className={styles.date}>{publishedAt}</span>
      </header>
      <h2 className={styles.title}>{title}</h2>
      {/* eslint-disable react/no-danger */}
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {/* eslint-enable react/no-danger */}
      <p>{t("EARN.SHOW_MORE")}</p>
    </Link>
  );
};

export const DummyNewsListItem = (): React$Node => (
  <div className={styles.dummy_block}>
    <div className={styles.dummy_top}>
      <div className={styles.dummy_date} />
    </div>
    <div className={styles.dummy_title} />
  </div>
);

export default NewsListItem;
