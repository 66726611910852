/* @flow */

import React, { useContext } from "react";
import { useData, useSendMessage } from "crustate/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { TransactionsData, CustomerData } from "data";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import cn from "classnames";
import { HeadlessLastOrders } from "components/AccountView/last-orders";
import { setMode, MODE } from "state/view-mode";

import styles from "./styles.scss";

type Props = {
  className: string,
};

const AppFooter = ({ className = "" }: Props): React$Node => {
  const t = useTranslate();
  const location = useLocation();
  const sendMessage = useSendMessage();
  const {
    content: { appfooter, appfootertransactions },
    categories,
    routes,
    configuration,
    configuration: { showCookieConsent, showPrivacyNotice },
  } = useContext(StoreInfoContext);
  const transaction = useData(TransactionsData);
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;
  const {
    footerText,
    copyrightText,
    leftColumnHeading,
    leftColumnText,
    leftColumnButtonText,
    leftColumnButtonLink,
    rightColumnHeading,
    rightColumnText,
    rightColumnButtonText,
    rightColumnButtonLink,
    transactionsHeading,
    transactionsText,
    disableDividers,
  } = appfooter;
  const consentActivated = (showCookieConsent !== null || showCookieConsent !== undefined) &&
    showCookieConsent === true;

  const termsLink = routes.termsView && routes.termsView.toggle !== undefined &&
  routes.termsView.toggle && routes.termsView.url !== undefined &&
  routes.termsView.url && routes.termsView.title !== undefined && routes.termsView.title ?
    {
      title: routes.termsView.title,
      url: routes.termsView.url,
    } : null;

  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";

  const displayTransactions = () => {
    let res = false;
    const home = appfootertransactions.home !== undefined &&
      appfootertransactions.home !== null &&
      appfootertransactions.home === true;
    const other = appfootertransactions.other !== undefined &&
      appfootertransactions.other !== null &&
      appfootertransactions.other === true;

    if (home && (location.pathname === "/" || location.pathname.includes("index.html"))) {
      res = true;
      return res;
    }

    if (other) {
      res = true;
    }

    for (const [key, value] of Object.entries(appfootertransactions)) {
      const exists = value !== undefined &&
        value !== null &&
        value === true;

      if (location.pathname.split("/")[1] === key && exists) {
        res = value;
        return res;
      }
    }

    return res;
  };

  return (
    <footer className={cn(styles.block, className, "awardit-appFooter")}>
      <div className={styles.large}>
        {displayTransactions() && transaction.state === "LOADED" && transaction.data.length > 0 ? (
          <Wrapper>
            <div className={cn("awardit-appFooterTransaction", styles.transaction)}>
              {transactionsHeading && (
                <div className={cn("awardit-appFooterTransactionLeft", styles.left)}>
                  <h2 className={styles.title}>{transactionsHeading}</h2>
                  {transactionsText &&
                    <p className={styles.description}>
                      {transactionsText}
                    </p>
                  }
                  {routes.accountView && routes.accountView.url &&
                    <Button className="awardit-appFooterTransactionLeftButton" to={routes.accountView.url} variant="primary">
                      {t("FOOTER.LINKS.GO_TO_MY_ACCOUNT")}
                    </Button>
                  }
                </div>
              )}
              {routes.accountTransactionsView && routes.accountTransactionsView.url &&
                <div className={cn("awardit-appFooterTransactionRight", styles.right)}>
                  {routes.accountTransactionsView?.url &&
                    <Link className={styles.link} to={routes.accountTransactionsView.url}>
                      <HeadlessLastOrders />
                    </Link>
                  }
                  <div className={styles.section__bottom}>
                    <Button
                      className={cn("awardit-appFooterTransactionRightButton", styles.link, styles.centeredCta)}
                      to={routes.accountTransactionsView?.url}
                    >
                      {t("FOOTER.LINKS.ALL_TRANSACTIONS")}
                    </Button>
                  </div>
                </div>
              }
            </div>
          </Wrapper>
        ) : null }

        {appfooter && appfooter.hideCategories !== true &&
          <Wrapper>
            <div className={cn("awardit-appFooterCategories", styles.categories)}>
              <h1>{t("HEADER.CATEGORIES")}</h1>
              <div className={cn("awardit-appFooterCategorieLinks", styles.links)}>
                {categories
                  .filter(item => !memberTargetList ||
                  memberTargetList.includes(item.awarditTargetId) ||
                  !item.awarditTargetId)
                  .map(category => (
                    <Link
                      key={category.name}
                      className={styles.link}
                      to={{
                        pathname: category.url,
                        state: { hint: { type: "category", category } },
                      }}
                    >
                      {category.name}
                    </Link>
                  ))}
              </div>
            </div>
          </Wrapper>
        }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        {(leftColumnHeading || rightColumnHeading) &&
          <Wrapper>
            <div className={cn("awardit-appFooterSection", styles.section)}>
              {leftColumnHeading &&
                <div className={cn("awardit-appFooterSectionLeft", styles.left)}>
                  <h2 className={styles.title}>{leftColumnHeading}</h2>
                  {leftColumnText &&
                    <p className={styles.description}>
                      {leftColumnText}
                    </p>
                  }
                  {leftColumnButtonLink && leftColumnButtonText &&
                    <Button className={cn("awardit-appFooterSectionLeftButton", styles.link)} to={leftColumnButtonLink}>
                      {leftColumnButtonText}
                    </Button>
                  }
                </div>
              }
              {rightColumnHeading &&
                <div className={cn("awardit-appFooterSectionRight", styles.right)}>
                  <h2 className={styles.title}>{rightColumnHeading}</h2>
                  {/* eslint-disable react/no-danger */}
                  {rightColumnText &&
                    <div
                      className={cn("typography", styles.description)}
                      dangerouslySetInnerHTML={{ __html: rightColumnText }}
                    />
                  }
                  {/* eslint-enable react/no-danger */}
                  {rightColumnButtonLink && rightColumnButtonText &&
                    <Button className={cn("awardit-appFooterSectionRightButton", styles.link)} to={rightColumnButtonLink}>
                      {rightColumnButtonText}
                    </Button>
                  }
                </div>
              }
            </div>
          </Wrapper>
        }

        {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
          <div className={cn("awardit-appFooterDivider", styles.divider)} />
        }

        <Wrapper className={cn("awardit-appFooterTerms", styles.wrapper)}>
          <div className={styles.row}>
            <section className={cn("awardit-appFooterTermsContent", styles.content)}>
              {footerText &&
                <p>
                  {footerText}
                </p>
              }
              {termsLink &&
                <Button
                  className={cn("awardit-appFooterTermsButton", styles.link)}
                  to={termsLink.url}
                >
                  {termsLink.title}
                </Button>
              }
              {consentActivated &&
                <Button
                  className={styles.link}
                  onClick={() => sendMessage(setMode(MODE.COOKIE_CONSENT))}
                >
                  {t("FOOTER.NEED_HELP.COOKIES")}
                </Button>
              }
              {Boolean(showPrivacyNotice) &&
                <Button className={styles.link} to="privacy-notice">
                  {t("FOOTER.LEGAL.PRIVACY_NOTICE")}
                </Button>
              }
              <Button className={styles.link} to={shopTermsUrl}>
                {t("FOOTER.LEGAL.TERMS")}
              </Button>
            </section>
          </div>
        </Wrapper>

        {copyrightText &&
          <>
            {disableDividers !== null && disableDividers !== undefined && !disableDividers &&
              <div className={cn("awardit-appFooterDivider", styles.divider)} />
            }

            <Wrapper className={cn("awardit-appFooterCopy", styles.copyWrapper)}>
              <section className={styles.copyright}>
                <div className={cn("awardit-appFooterCopyText", styles.row)}>
                  <span>{copyrightText}</span>
                </div>
              </section>
            </Wrapper>
          </>
        }
      </div>
    </footer>
  );
};

export default AppFooter;
